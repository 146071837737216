import React, { useEffect, useState, useRef, useMemo } from "react";
import "./Radar.css";

const Radar = ({ mapImgSrc, coordinates = [] }) => {
    const [ xLeft, setXleft ] = useState(0);
    const [ yTop, setYtop ] = useState(0);
    const [ coordinateIndex, setCoordinateIndex ] = useState(0);
    const [ status, setStatus ] = useState("Searching.");
    const radarRef = useRef(null);
    const timeoutDuration = useRef(300);
    const isInViewport = useIsInViewport(radarRef);
    useEffect(()=>{
        if ( isInViewport ) {
            const coordinateTarget = coordinates[coordinateIndex];
            const coordinateXTarget = coordinateTarget[0];
            const coordinateYTarget = coordinateTarget[1];
            setYtop( coordinateYTarget );
            setXleft( coordinateXTarget );
            setCoordinateIndex(coordinateIndex+1);
        }
        
    }, []);

    useEffect(()=>{
        // Have map move as well.
        if ( isInViewport ) {
            if ( coordinateIndex > 0 ) {
                timeoutDuration.current = 1000;
            }
            if ( coordinateIndex < coordinates.length ) {
                const coordinateTarget = coordinates[coordinateIndex];
                const coordinateXTarget = coordinateTarget[0];
                const coordinateYTarget = coordinateTarget[1];
                setTimeout(()=>{
                    setYtop( coordinateYTarget );
                    setXleft( coordinateXTarget );
                    setCoordinateIndex(coordinateIndex+1);
                }, timeoutDuration.current );
            }
            else {
                setStatus("Found.");
            }
        }
    });
    return(
        <div className="radar" ref={radarRef}>
            <hr className="x" style={{"left": xLeft+"%"}}/>
            <hr className="y"  style={{"top": yTop+"%"}}/>
            <img src={mapImgSrc} alt="radar map"/>
            <div className="status-readout">
                <p>{status}</p>
            </div>
            <div className="coordinates">
                <p>{xLeft}, {yTop}</p>
            </div>
        </div>
    );
}

const useIsInViewport = (ref) => {
    const [intersecting, setIntersecting] = useState(false);
    const observer = useMemo(
        () => 
            new IntersectionObserver(([entry]) => 
                setIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);
    return intersecting;
}

export default Radar;