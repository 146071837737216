import React, { useState, useRef } from "react";
import TextDecrypt from "../components/TextDecrypt";
import TextTelepromter from "../components/TextTelepromter";
import TextGlitch from "../components/TextGlitch.js";
import Radar from "../components/Radar.js";
import MaterialIcon from "material-icons-react";
import './Home.css';


export default function Home() {
    
    const [contactFormData, setContactFormData] = useState({});
    const [contactFormSubmitFeedback, setContactFormSubmitFeedback ] = useState({
        status: null,
        message: null
    });
    const [mobileMenuDisplay, setMobileMenuDisplay] = useState(false);
    const [mobileMenuDisplayClassName, setmobileMenuDisplayClassName] = useState("menu-items");

    const techSkillsAnimationTimeout = 100;
    const [animTechSkillsGroup0, setAnimTechSkillsGroup0] = useState(0);

    const section0AnimationTimeout = 500;
    const [animCurrentGroup0, setAnimCurrentGroup0 ] = useState(0);
    const [animCurrentGroup1, setAnimCurrentGroup1 ] = useState(0);
    const [animCurrentGroup2, setAnimCurrentGroup2 ] = useState(0);
    const [animCurrentGroup3, setAnimCurrentGroup3 ] = useState(0);
    const [animCurrentGroup4, setAnimCurrentGroup4 ] = useState(0);
    const [animCurrentGroup5, setAnimCurrentGroup5 ] = useState(0);
    const [animCurrentGroup6, setAnimCurrentGroup6 ] = useState(0);

    const aboutSectionRef = useRef(null);
    const projectSectionRef = useRef(null);
    const contactSectionRef = useRef(null);

    const startTechSkillAnim0 = ( animationOrder ) => {
        if(animTechSkillsGroup0 === animationOrder){
            return true;
        }
        return false;
    }
    const updateTechSkillAnim0 = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimTechSkillsGroup0(itemIndex+1);
            }, techSkillsAnimationTimeout)
        }
    }
    const startAnimation0 = ( animationOrder ) => {
        if(animCurrentGroup0 === animationOrder){
            return true;
        }
        return false;
    }
    const updateAnimation0Status = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimCurrentGroup0(itemIndex+1);
            }, section0AnimationTimeout)
        }
    }
    const startAnimation1 = ( animationOrder ) => {
        if(animCurrentGroup1 === animationOrder){
            return true;
        }
        return false;
    }
    const updateAnimation1Status = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimCurrentGroup1(itemIndex+1);
            }, section0AnimationTimeout)
        }
    }
    const startAnimation2 = ( animationOrder ) => {
        if(animCurrentGroup2 === animationOrder){
            return true;
        }
        return false;
    }
    const updateAnimation2Status = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimCurrentGroup2(itemIndex+1);
            }, section0AnimationTimeout)
        }
    }
    const startAnimation3 = ( animationOrder ) => {
        if(animCurrentGroup3 === animationOrder){
            return true;
        }
        return false;
    }
    const updateAnimation3Status = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimCurrentGroup3(itemIndex+1);
            }, section0AnimationTimeout)
        }
    }
    const startAnimation4 = ( animationOrder ) => {
        if(animCurrentGroup4 === animationOrder){
            return true;
        }
        return false;
    }
    const updateAnimation4Status = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimCurrentGroup4(itemIndex+1);
            }, section0AnimationTimeout)
        }
    }
    const startAnimation5 = ( animationOrder ) => {
        if(animCurrentGroup5 === animationOrder){
            return true;
        }
        return false;
    }
    const updateAnimation5Status = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimCurrentGroup5(itemIndex+1);
            }, section0AnimationTimeout)
        }
    }
    const startAnimation6 = ( animationOrder ) => {
        if(animCurrentGroup6 === animationOrder){
            return true;
        }
        return false;
    }
    const updateAnimation6Status = ( itemIndex, status ) => {
        if ( status === 'done' ) {
            setTimeout(()=>{
                setAnimCurrentGroup6(itemIndex+1);
            }, section0AnimationTimeout)
        }
    }
    let handleFieldChange = (e) => {
        setContactFormData({...contactFormData, [e.target.name] : e.target.value });
    }

    let handleContactFormSubmit = async (ev) => {
        ev.preventDefault();
        await submitMessage( contactFormData ).then((res)=>{
            if ( res.success == true ) {
                setContactFormSubmitFeedback( { status: "success", message: "Thank you, your message has successfully been sent."} );
                ev.target.reset();
            }
            else {
                setContactFormSubmitFeedback( { status: "failed", message: "There was an error while sending your message, the admin has been notified and will let you know once the issue has been resolved."} );
            }
        })
    }
    let handleMobileMenuToggle = () => {
        if (mobileMenuDisplay) {
            setMobileMenuDisplay(false);
            setmobileMenuDisplayClassName("menu-items");
        }
        else {
            setMobileMenuDisplay(true);
            setmobileMenuDisplayClassName("menu-items display");
        }
    }
    async function submitMessage(formData) {
        return fetch('https://my-site-backend-hr3pwouega-uc.a.run.app/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData) 
        })
        .then(data=>data.json())
        .catch(error=>error.json())
    }

    let scrollToSection = (targetClassName) => {
        let navEl = document.querySelector("nav.menu");
        let targetEl = document.querySelector(targetClassName);
        const navHeight = navEl.offsetHeight;
        const targetElPosition = targetEl.getBoundingClientRect().top;
        const offsetTargetElPosition = targetElPosition + window.scrollY - navHeight;
        window.scrollTo({
            top: offsetTargetElPosition,
            behavior: "smooth"
        });
    }

    return(
        <div className="main">
            
            <nav className="menu">
                <div className="menu-content">
                    <div id="menu-title-wrapper">
                        <img className="short-logo" src="/assets/eye-candy/logo.png" alt="Alex Gwak"/>
                    </div>

                    <div className="menu-container">
                        <div className="mobile-menu">
                            <div className="mobile-menu-dropdown" onClick={handleMobileMenuToggle}><MaterialIcon icon="menu" color='#ffff' size={30}/></div>
                        </div>
                        <div className={mobileMenuDisplayClassName}>
                            <div className="menu-item" onClick={() => {scrollToSection(".about")}}><p>About</p></div>
                            <div className="menu-item" onClick={() => {scrollToSection(".projects")}}><p>Projects</p></div>
                            <div className="menu-item" onClick={() => {scrollToSection(".contact-me")}}><p>Contact</p></div>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="hero">
                <div className="landing-container">
                    <div id="home">
                        <div id="name-wrapper">
                            <TextGlitch>
                                <TextDecrypt
                                    sourceText="Alex"
                                    enableTwitch={true}
                                    fontSize={"large"}
                                    shuffleMax={40}
                                    shuffleSpeed={20}
                                />
                            </TextGlitch>
                            <TextGlitch>
                                <TextDecrypt
                                    sourceText="Gwak"
                                    enableTwitch={true}
                                    fontSize={"large"}
                                    shuffleMax={40}
                                    shuffleSpeed={20}
                                />
                            </TextGlitch>
                        </div>
                        <div className="intro-tags">
                            <p className="intro-tag">Fullstack Developer</p>
                            <hr/>
                            <p className="intro-tag">Designer</p>
                        </div>
                        <img className="eye-candy" id="diagonal-lines-box" src="/assets/eye-candy/diagonal-lines-box.png" alt="diagonal-lines-box" />

                    </div>
                </div>
                <div className="scroll-down-prompt-container">
                    <p className="scroll-down-arrow">{">"}</p>
                    <p className="scroll-down-arrow">{">"}</p>
                    <p className="scroll-down-arrow">{">"}</p>
                </div>
                <div className="section-index-container">
                    <hr/>
                    <p className="section-index">00.</p>
                </div>
                <div className="background">
                    <div id="bg-terminal-1" className="terminal-window">
                        <div className="terminal-window-menu"></div>
                        <div className="terminal-window-body">
                            <TextTelepromter
                                id={"background-0-item-0"}
                                sourceTextLines={[
                                    "Lorem ipsum dolor sit amet.", 
                                    "pellentesque purus.",
                                    "Morbi faucibus maximus varius.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "scelerisque sapien sagittis.",
                                ]}
                                maxLines={50}
                                sourceTypeSpeed={10}
                                sourcePauseCycles={0}
                                loop={true}
                            />
                        </div>
                    </div>
                    <div id="bg-terminal-0" className="terminal-window">
                        <div className="terminal-window-menu"></div>
                        <div className="terminal-window-body">
                            <TextTelepromter
                                id={"background-0-item-1"}
                                sourceTextLines={[
                                    "Lorem ipsum dolor sit amet.", 
                                    "pellentesque purus.",
                                    "Morbi faucibus maximus varius.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                    "Donec odio sem, tincidunt vel.",
                                    "Phasellus porta augue a mauris.",
                                    "scelerisque sapien sagittis.",
                                ]}
                                maxLines={50}
                                sourceTypeSpeed={10}
                                sourcePauseCycles={0}
                                loop={true}
                            />
                        </div>
                    </div>
                </div>                
            </div>

            <div className="about" ref={aboutSectionRef}>
                <div className="about-row-container">
                    <div className="about-title">
                        <TextGlitch>
                            <TextDecrypt
                                sourceText="About"
                                enableTwitch={true}
                                fontSize={"large"}
                                shuffleMax={40}
                                shuffleSpeed={20}
                            />
                        </TextGlitch>
                    </div>
                    <div className="about-row-container">
                        <div className="about-column-container">
                            <div className="badge-info-container">
                                <img className="badge" src="/assets/eye-candy/fullstack-developer-icon.png" alt="Fullstack developer badge"/>
                                <div className="badge-title-area">
                                    <div className="title-wrapper">
                                        <TextGlitch>
                                            <TextDecrypt
                                                sourceText="Full"
                                                enableTwitch={true}
                                                fontSize={"medium-large"}
                                                shuffleMax={40}
                                                shuffleSpeed={20}
                                            />
                                        </TextGlitch>
                                        <TextGlitch>
                                            <TextDecrypt
                                                sourceText="Stack"
                                                enableTwitch={true}
                                                fontSize={"medium-large"}
                                                shuffleMax={40}
                                                shuffleSpeed={20}
                                            />
                                        </TextGlitch>
                                        <TextGlitch>
                                            <TextDecrypt
                                                sourceText="Developer"
                                                enableTwitch={true}
                                                fontSize={"medium-large"}
                                                shuffleMax={40}
                                                shuffleSpeed={20}
                                            />
                                        </TextGlitch>
                                    </div>
                                    <div className="hr-wrapper">
                                        <hr className="line"/>
                                        <hr className="square"/>
                                    </div>
                                </div>
                            </div>
                            <div className="about-info-container">
                                <p className="about-bio-text">
                                    Hey there, I'm Alex. I'm a full stack developer with 6 years of working experience in the software industry, currently working at MDA Space as a software engineer. A lot of my experience comes from developing business tools/dashboards on the front and back end,
                                    working on satellite imaging applications and services for MDA Space, and developing mobile applications. I have also worked on customizing WordPress websites/themes and plugins for clients. Languages/frameworks/tools I use daily are React, Redux, Angular, Node.JS, and TypeScript, however also have a history of working with the other languages and frameworks that's listed here. 
                                    Most of the applications I work on utilize continuous deployment with cloud services like GCP and containerized with Docker.
                                    I'm also well versed in Adobe Illustrator to design assets such as app icons and general design assets. I have a strong passion for creating interesting and meaningful software solutions, and enjoy designing eye-catching and unique interfaces!
                                </p>
                            </div>
                            <div className="location">
                                <h3 className="detail-title">Location</h3>
                                <h3>Vancouver, BC</h3>
                                <div className="terminal-view">
                                    <div className="terminal-menu">
                                        <h3 className="terminal-title">Location</h3>
                                    </div>
                                    <div className="terminal-body">
                                        <Radar
                                            mapImgSrc={"/assets/eye-candy/canada-map.png"}
                                            coordinates={[
                                                [50,50],
                                                [20, 20],
                                                [90, 90],
                                                [45, 40],
                                                [10, 70]
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tech-stack-container">
                            <div className="terminal-window">
                                <div className="terminal-window-menu"></div>
                                <div className="terminal-window-body">
                                    <TextTelepromter
                                        id={"tech-skill-terminal"}
                                        sourceTextLines={[
                                            "ls /user/alex-gwak/technical-skills",
                                        ]}
                                        maxLines={2}
                                        sourceTypeSpeed={20}
                                        sourcePauseCycles={500}
                                        loop={false}
                                        className="tech-stack-item" 
                                        animationOrder={0}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                        lineInPreText={"> "}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-0"}
                                        sourceTextLines={[
                                            "React", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={1}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-1"}
                                        sourceTextLines={[
                                            "React Native", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={2}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-2"}
                                        sourceTextLines={[
                                            "Redux", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={3}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-3"}
                                        sourceTextLines={[
                                            "Angular", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={4}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-4"}
                                        sourceTextLines={[
                                            "TypeScript", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={5}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-5"}
                                        sourceTextLines={[
                                            "Node.JS", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={6}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-6"}
                                        sourceTextLines={[
                                            "Python", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={7}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-7"}
                                        sourceTextLines={[
                                            "C", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={8}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-8"}
                                        sourceTextLines={[
                                            "C#", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={9}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-9"}
                                        sourceTextLines={[
                                            "SQL / NoSQL", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={10}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-10"}
                                        sourceTextLines={[
                                            "Google Cloud Platform (GCP)", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={11}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-11"}
                                        sourceTextLines={[
                                            "Lemp/Lamp", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={12}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-12"}
                                        sourceTextLines={[
                                            "Kubernetes", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={13}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-13"}
                                        sourceTextLines={[
                                            "Docker", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={14}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-14"}
                                        sourceTextLines={[
                                            "WordPress", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={15}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-15"}
                                        sourceTextLines={[
                                            "Argon2", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={16}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-16"}
                                        sourceTextLines={[
                                            "Jest", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={17}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                    <TextTelepromter
                                        id={"tech-skill-item-17"}
                                        sourceTextLines={[
                                            "PHP", 
                                        ]}
                                        maxLines={1}
                                        loop={false}
                                        sourceTypeSpeed={3}
                                        sourcePauseCycles={100}
                                        animationOrder={18}
                                        startAnimation={startTechSkillAnim0}
                                        updateStatus={updateTechSkillAnim0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projects" ref={projectSectionRef}>
                <div className="projects-container">
                    <div className="projects-title">
                        <TextGlitch>
                            <TextDecrypt
                                sourceText="Projects"
                                enableTwitch={true}
                                fontSize={"large"}
                                shuffleMax={40}
                                shuffleSpeed={20}
                            />
                        </TextGlitch>
                    </div>

                    <div className="projects-sub-title-container">
                        <h2 className="projects-sub-title">Dashboard</h2>
                        <hr/>
                        <h2 className="projects-sub-title">Cloud Computing</h2>
                        <hr/>
                        <h2 className="projects-sub-title">Database</h2>
                        <hr/>
                        <h2 className="projects-sub-title">Contract tools</h2>
                    </div>       

                    <div className="projects-info-container">
                        <div className="project-column-container">
                            <div className="project-container">
                                <TextTelepromter
                                    id={"group-0-item-0"}
                                    sourceTextLines={[
                                        "Satellite imaging software, and tools",
                                    ]}
                                    maxLines={1}
                                    sourceTypeSpeed={20}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-title" 
                                    animationOrder={0}
                                    startAnimation={startAnimation0}
                                    updateStatus={updateAnimation0Status}
                                />
                                <TextTelepromter
                                    id={"group-0-item-1"}
                                    sourceTextLines={[
                                        "I am currently working on various satellite imaginge software, tools and services at Space. My duties range from working with my team to creating new features, and fixing existing bugs for our existing range of technologies.",
                                    ]}
                                    maxLines={50}
                                    sourceTypeSpeed={5}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-info" 
                                    animationOrder={1}
                                    startAnimation={startAnimation0}
                                    updateStatus={updateAnimation0Status}
                                />
                            </div>
                        </div>
                        <div className="projects-row-container">
                            <div className="project-container">
                                <TextTelepromter
                                    id={"group-1-item-0"}
                                    sourceTextLines={[
                                        "Recruiter Dashboard",
                                    ]}
                                    maxLines={1}
                                    sourceTypeSpeed={20}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-title" 
                                    animationOrder={0}
                                    startAnimation={startAnimation1}
                                    updateStatus={updateAnimation1Status}
                                />
                                <TextTelepromter
                                    id={"group-1-item-1"}
                                    sourceTextLines={[
                                        "Recruitment partners at TEEMA needed a way to visually compare monthly, quarterly, and annual performance metrics through visuals like charts and graphs that allow for comparing against other partners within the company. Users can select: datapoint, compared users, and time frame: (Quarter+Year, Monthly+Year, annual totals). In addition, their monthly reports are past reports can all be pulled on their individual pages.",
                                    ]}
                                    maxLines={50}
                                    sourceTypeSpeed={5}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-info" 
                                    animationOrder={1}
                                    startAnimation={startAnimation1}
                                    updateStatus={updateAnimation1Status}
                                />
                            </div>
                            <div className="project-container">
                                <TextTelepromter
                                    id={"group-2-item-0"}
                                    sourceTextLines={[
                                        "Member Dashboard",
                                    ]}
                                    maxLines={1}
                                    sourceTypeSpeed={20}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-title" 
                                    animationOrder={0}
                                    startAnimation={startAnimation2}
                                    updateStatus={updateAnimation2Status}
                                />
                                <TextTelepromter
                                    id={"group-2-item-1"}
                                    sourceTextLines={[
                                        "Partners at TEEMA wanted a way for members to set goals and track their progress towards them throughout the year. There are constants relative to each industry, member status, contract/perm ...etc which will alter the formula that's used to calculate goal metrics. Members are able to log current progress metrics and have charts display their this data against their goals. ",
                                    ]}
                                    maxLines={50}
                                    sourceTypeSpeed={5}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-info" 
                                    animationOrder={1}
                                    startAnimation={startAnimation2}
                                    updateStatus={updateAnimation2Status}
                                />
                            </div>
                        </div>
                        <div className="projects-column-container">
                            <div className="project-container">
                                <TextTelepromter
                                    id={"group-3-item-0"}
                                    sourceTextLines={[
                                        "Contract Generating Tool",
                                    ]}
                                    maxLines={1}
                                    sourceTypeSpeed={20}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-title" 
                                    animationOrder={0}
                                    startAnimation={startAnimation3}
                                    updateStatus={updateAnimation3Status}
                                />
                                <TextTelepromter
                                    id={"group-3-item-1"}
                                    sourceTextLines={[
                                        "There are a number of different contracts that are appropriate for different types of recruitment placements made at TEEMA.This tool generates forms based on doc templates that the staff can provide to the tool.The form that walks through the contract drafting process with the recuiter to determine the type of contract being drafted, and the content that will be populated into the template.Entries are logged into a specified google spreadsheet and will generate and email the contract to specified department emails for approval.",
                                    ]}
                                    maxLines={50}
                                    sourceTypeSpeed={5}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-info" 
                                    animationOrder={1}
                                    startAnimation={startAnimation3}
                                    updateStatus={updateAnimation3Status}
                                />
                            </div>
                            <div className="project-container">
                                <TextTelepromter
                                    id={"group-4-item-0"}
                                    sourceTextLines={[
                                        "Contract status tracker",
                                    ]}
                                    maxLines={1}
                                    sourceTypeSpeed={20}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-title" 
                                    animationOrder={0}
                                    startAnimation={startAnimation4}
                                    updateStatus={updateAnimation4Status}
                                />
                                <TextTelepromter
                                    id={"group-4-item-1"}
                                    sourceTextLines={[
                                        "The TEEMA contracts department needed a way to track the status of all contracts and their status (opened, read, signed, terminated...etc) as well as track metrics on the amount of contracts being fulfilled. This tools aggregates all active contracts from our contracts email account to automatically update their status on a specified google spreadsheet.",
                                    ]}
                                    maxLines={50}
                                    sourceTypeSpeed={5}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-info" 
                                    animationOrder={1}
                                    startAnimation={startAnimation4}
                                    updateStatus={updateAnimation4Status}
                                />
                            </div>
                            <div className="project-container">
                                <TextTelepromter
                                    id={"group-5-item-0"}
                                    sourceTextLines={[
                                        "Website overhaul",
                                    ]}
                                    maxLines={1}
                                    sourceTypeSpeed={20}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-title" 
                                    animationOrder={0}
                                    startAnimation={startAnimation5}
                                    updateStatus={updateAnimation5Status}
                                />
                                <TextTelepromter
                                    id={"group-5-item-1"}
                                    sourceTextLines={[
                                        "The TEEMA website initially was fairly barebones with little utility for users and utilized lots of plugins which reduced load times for the site. I worked with executives to re-design the site, create a job search page with more specific filtering options and descriptive urls, a new staff roster search page, nurse candidate checklist form which generates documents for recruiters to review, and other functional tools. The site was also migrated from GoDaddy to GCP.",
                                    ]}
                                    maxLines={50}
                                    sourceTypeSpeed={5}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-info" 
                                    animationOrder={1}
                                    startAnimation={startAnimation5}
                                    updateStatus={updateAnimation5Status}
                                />
                            </div>
                            <div className="project-container">
                                <TextTelepromter
                                    id={"group-6-item-0"}
                                    sourceTextLines={[
                                        "Website Scrapping",
                                    ]}
                                    maxLines={1}
                                    sourceTypeSpeed={20}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-title" 
                                    animationOrder={0}
                                    startAnimation={startAnimation6}
                                    updateStatus={updateAnimation6Status}
                                />
                                <TextTelepromter
                                    id={"group-6-item-1"}
                                    sourceTextLines={[
                                        "While making updates to the site it became evident that the staffing roster page was using a plugin. However, the license for the plugin had expired so we could no export or update the staffing roster. To solve the issue I wrote a web-scrapping tool to navigate through the existing roster page to extract and push staff details into a database which we then used for the new roster page.",
                                    ]}
                                    maxLines={50}
                                    sourceTypeSpeed={5}
                                    sourcePauseCycles={0}
                                    loop={false}
                                    className="project-info" 
                                    animationOrder={1}
                                    startAnimation={startAnimation6}
                                    updateStatus={updateAnimation6Status}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-me" ref={contactSectionRef}>
                <div className="contact-me-form-container">
                    <div className="contact-me-title">
                        <TextGlitch>
                            <TextDecrypt
                                sourceText="Contact"
                                enableTwitch={true}
                                fontSize={"large"}
                                shuffleMax={40}
                                shuffleSpeed={20}
                            />
                        </TextGlitch>
                    </div>
                    <div className="contact-me-title-sub">
                        <p>Hey, send me an email if you're interested in learning more about me or have any feedback.</p>
                    </div>

                    <form className="contact-me-form" onSubmit={handleContactFormSubmit}>
                        <div className="contact-me-form-response">
                            {
                                contactFormSubmitFeedback.status !== null 
                                ? <div className="contact-me-feedback"><p className="submit-result">{contactFormSubmitFeedback.message}</p></div>
                                : ""
                            }
                        </div>

                        <div className="input-field required">
                            <p className="input-label">Your name</p>
                            <input required name="Name" type={"text"} onChange={handleFieldChange} />
                        </div>
                        <div className="input-field required">
                            <p className="input-label">Your email</p>
                            <input required name="Email" type={"email"} onChange={handleFieldChange} />
                        </div>
                        <div className="input-field message required">
                            <p className="input-label">Your message</p>
                            <textarea required name="Message"  onChange={handleFieldChange} />
                        </div>
                        <button className="submit-message"><p>Submit</p></button>
                    </form>
                </div>
            </div>
            
            <div className="footer">
                <div className="footer-img-container">
                    <img className="footer-img" src="/assets/eye-candy/logo.png" alt="Alex Gwak Logo"/>
                </div>
            </div>
        </div>
    );
}
