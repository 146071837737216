import React, { useEffect, useState } from "react";
import "./TextDecrypt.css";
/**
 * Each letter needs to know:
 * - how many character swaps done / required ?
 * - index within string
*/
const Decoder = ( { sourceText, fontSize, shuffleMax, shuffleSpeed, enableTwitch = false, disintigrate = false } ) => {
    const [ chars, setChars ] = useState([]);
    const [ decoded, setDecoded ] = useState(false);
    let getRandomChar = ( exclude = false ) => {
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+|`~';
        let charsArr = chars.split("");
        let randomChar = "";
        if ( exclude ) {
            if ( charsArr.includes(exclude) ) {
                charsArr.splice( chars.indexOf(exclude), 1 );
            }
        }
        randomChar = charsArr[Math.floor( Math.random() * (charsArr.length-1) )];
        return ( randomChar );
    }
    useEffect(() => {
        let textCpy = sourceText.split("");
        let textChars = [];
        let charIndex = 0;
        textCpy.forEach((char) => {
            textChars.push(
                {
                    key: charIndex,
                    letter: char,
                    mask: getRandomChar(char),
                    shuffle: Math.floor( Math.random() * shuffleMax )
                }
            )
            charIndex++;
        });
        setChars(textChars);
    }, [sourceText, shuffleMax]);

    useEffect(() => {
        if ( disintigrate ) {
            console.log("Disitingrate text.");
        } else {
            if ( decoded && enableTwitch ) {
                let charsCpy = [...chars];
                if ( charsCpy.length > 0 ) {
                    const randA = Math.floor( Math.random() * 200 );
                    const randB = Math.floor( Math.random() * 200 );
                    if ( randA === randB ) {                
                        let charIndex = Math.floor( Math.random() * (sourceText.length - 1) );
                        charsCpy[charIndex].mask = getRandomChar( charsCpy[charIndex].mask );
                        charsCpy[charIndex].shuffle = Math.floor( Math.random() * shuffleMax );
                        setChars(charsCpy);
                    }
                    setDecoded(false);
                }
            }
            else if ( !decoded ) {
                let done = true;
                setTimeout( () => {
                    let textChars = [...chars];
                    textChars.forEach((char) => {
                        if ( char.shuffle > 0 ) {
                            char.mask = getRandomChar(char.letter);
                            char.shuffle--;
                            done = false;
                        }
                        else if ( char.shuffle === 0 ) {
                            char.mask = char.letter;
                        }
                    });
                    setDecoded(done);
                    setChars(textChars);
                }, shuffleSpeed );
            }
        }

    }, [decoded, enableTwitch, chars, shuffleSpeed, sourceText, shuffleMax]);

    return(
        <div className={"decode fs-"+fontSize}>
            {chars.map(char => {
                return(<p key={char.key} >{char.mask}</p>);
            })}
        </div>
    );
}
export default Decoder;