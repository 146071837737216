import React, { useEffect, useState } from "react";
import "./TextGlitch.css";

const TextGlitch = ( { children, originalGlitchDuration = 10, glitchRadius = 5, positionType = "relative", top = 0, right = 0, trigger = false } ) => {
    const [ isGlitch, setIsGlitch ] = useState(false);
    const [ attempt, setAttempt ] = useState(0);
    const [ glitchDuration, setGlitchDuration ] = useState(originalGlitchDuration);

    const doGlitch = ( randA, randB ) => {
        setTimeout( () => {
            if ( randA === randB ) {
                setIsGlitch(true);
            }
            setAttempt( attempt+1 );
        }, 500)
    }
    const reduceGlitchDuration = () => {
        setTimeout(()=>{
            setGlitchDuration(glitchDuration-1);
        }, 20)
    }
    const stopGlitch = () => {
        setIsGlitch(false);
        setAttempt( 0 );
        setGlitchDuration(originalGlitchDuration);
    }
    useEffect( () => {
        if ( trigger === false || isGlitch ) {
            if ( isGlitch ) {
                if ( glitchDuration === 0 ) {
                    stopGlitch();
                } else {
                    reduceGlitchDuration();
                }
            }
            else {
                doGlitch( Math.floor( Math.random() * 5 ), Math.floor( Math.random() * 5 ) );
            }
        }

    }, [attempt, glitchDuration, isGlitch, originalGlitchDuration, trigger]);
    return(
        <div className={"glitch-text" + (isGlitch ? " glitch" : "")} style={{position: positionType, top: top, right: right}} onMouseEnter={()=>{if(isGlitch === false && trigger === true) { console.log("triggered");setIsGlitch(true); }}} onMouseLeave={()=>{if(trigger === true ) {stopGlitch();}}}>
            <div className={"layer-0"}>{children}</div>
            <div className={"layer-1"} style={{
                left:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ), 
                right:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ), 
                top:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ), 
                bottom:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ),
                zIndex:Math.floor( Math.random() * 3 ) 
            }}>{children}</div>
            <div className={"layer-2"} style={{
                left:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ), 
                right:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ), 
                top:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ), 
                bottom:Math.floor( Math.random() * ( Math.floor( Math.random * 1 ) === 1 ? -glitchRadius : glitchRadius ) ),
                zIndex:Math.floor( Math.random() * 3 ) 
            }}>{children}</div>
        </div>
    );
}

export default TextGlitch;