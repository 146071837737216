import React, { useEffect, useState, useRef, useMemo } from "react";
import "./TextTelepromter.css";

const TextTelepromter = ( { lineInPreText = false, animationOrder = false, updateStatus = false, startAnimation = false, sourceTextLines, maxLines, sourceTypeSpeed = 50, sourcePauseCycles = 4, loop = false, id = false, className = false, style} ) => {

    const [ textLines, setTextLines ] = useState([]);
    const [ textLineIndex, setTextLineIndex ] = useState(0);
    const [ cursor, setCursor ] = useState("|");
    const [ isPaused, setIsPaused ] = useState(false);
    const [ pauseCycles , setPauseCycles ] = useState(sourcePauseCycles);
    const animationStatus = useRef("pending");
    const telepromterRef = useRef(null);
    const isInViewport = useIsInViewport(telepromterRef);


    useEffect(() => {
        if ( animationStatus.current === 'pending' ) {
            let textLinesCpy = [...sourceTextLines]
            let textLines = [];
            let textIndex = 0;
            textLinesCpy.forEach((textLine) => {
                if ( textLine.length > 0 ) {
                    textLines.push(
                        {
                            key: textIndex,
                            text: textLine,
                            mask: "",
                            tail: 0
                        }
                    )
                    textIndex++;
                }
    
            });
            setTextLines(textLines);
        }
    }, [sourceTextLines]);

    useEffect(() => {
        // If the component animation is part of sequence
        if ( startAnimation !== false ) {
            // Will only start if previous index component animation is done
            const canStartAnimation = startAnimation(animationOrder);
            if ( isInViewport === true && canStartAnimation === true ) {
                let textLinesCpy = [...textLines];
                let textLineIndexCpy = textLineIndex;
                let textLineFinished = false;
                if ( textLineIndexCpy <= textLinesCpy.length - 1 ) {
                    if ( isPaused ) {
                        if ( pauseCycles === 0 ) {
                            setIsPaused(false);
                        } else {
                            setTimeout(()=>{
                                if ( cursor ===  "" ) {
                                    setCursor("|");
                                }
                                else {
                                    setCursor("");
                                }
                                setPauseCycles(pauseCycles-1);
                            }, 500);
                        }
        
                    }
                    else {
                        animationStatus.current = 'started';
                        updateStatus(animationOrder, 'started');
                        setTimeout(() => {
                            textLinesCpy.forEach((textLine) => {
                                if ( textLine.key <= textLineIndexCpy ) {
                                    if ( textLine.tail === textLine.text.length ) {
                                        textLineIndexCpy++;
                                        textLineFinished = true;
                                    }
                                    if ( textLine.tail <= textLine.text.length ) {
                                        textLine.mask = textLine.text.slice( 0 , textLine.tail++ );
                                    }
                                }
                            });
                            if ( textLineFinished ) {
                                setPauseCycles(sourcePauseCycles);
                                setIsPaused(true);
                            }
                            setTextLines(textLinesCpy);
                            setTextLineIndex(textLineIndexCpy);
                            setCursor("|");
                        }, 
                        sourceTypeSpeed );
                    }
                }
                else if (loop) {
                    let textLinesCpy = [...sourceTextLines]
                    let textLines = [];
                    let textIndex = 0;
                    textLinesCpy.forEach((textLine) => {
                        textLines.push(
                            {
                                key: textIndex,
                                text: textLine,
                                mask: "",
                                tail: 0
                            }
                        )
                        textIndex++;
                    });
                    setTextLineIndex(0);
                    setTextLines(textLines);
                    animationStatus.current = 'loop';
                    updateStatus(animationOrder, 'loop');
                }
                else if (!loop) {
                    animationStatus.current = 'done';
                    updateStatus(animationOrder, 'done');
                    setCursor("");
                }
            }
            else {
                setCursor("");
            }
        }
        else {
            if ( isInViewport === true ) {
                let textLinesCpy = [...textLines];
                let textLineIndexCpy = textLineIndex;
                let textLineFinished = false;
                if ( textLineIndexCpy <= textLinesCpy.length - 1 ) {
                    if ( isPaused ) {
                        if ( pauseCycles === 0 ) {
                            setIsPaused(false);
                        } else {
                            setTimeout(()=>{
                                if ( cursor ===  "" ) {
                                    setCursor("|");
                                }
                                else {
                                    setCursor("");
                                }
                                setPauseCycles(pauseCycles-1);
                            }, 500);
                        }
        
                    }
                    else {
                        animationStatus.current = 'started';
                        setTimeout(() => {
                            textLinesCpy.forEach((textLine) => {
                                if ( textLine.key <= textLineIndexCpy ) {
                                    if ( textLine.tail === textLine.text.length ) {
                                        textLineIndexCpy++;
                                        textLineFinished = true;
                                    }
                                    if ( textLine.tail <= textLine.text.length ) {
                                        textLine.mask = textLine.text.slice( 0 , textLine.tail++ );
                                    }
                                }
                            });
                            if ( textLineFinished ) {
                                setPauseCycles(sourcePauseCycles);
                                setIsPaused(true);
                            }
                            setTextLines(textLinesCpy);
                            setTextLineIndex(textLineIndexCpy);
                            setCursor("|");
                        }, 
                        sourceTypeSpeed );
                    }
                }
                else if (loop) {
                    let textLinesCpy = [...sourceTextLines]
                    let textLines = [];
                    let textIndex = 0;
                    textLinesCpy.forEach((textLine) => {
                        textLines.push(
                            {
                                key: textIndex,
                                text: textLine,
                                mask: "",
                                tail: 0
                            }
                        )
                        textIndex++;
                    });
                    setTextLineIndex(0);
                    setTextLines(textLines);
                    animationStatus.current = 'loop';
                }
                else if (!loop) {
                    animationStatus.current = 'done';
                    setCursor("");
                }
            }
        }
    }, [cursor, isPaused, setCursor, setIsPaused, sourceTypeSpeed, textLineIndex, textLines, setPauseCycles, pauseCycles, sourcePauseCycles, sourceTextLines, isInViewport]);
    
    return(
        <div id={id} className={"telepromter" + ( className ? " " + className : "") } ref={telepromterRef} >
            <div className="ghost-text">
                {sourceTextLines.map((textLine, index)=>{
                        return (<p key={id+"-ghost-"+index}>{lineInPreText ? lineInPreText + " " + textLine : textLine }</p>);
                })}
            </div>
            <div className="line-out">
                {textLines
                .filter(textLine => {
                    /* Filter text lines that should be showing, if max text lines in view is reached, filter out text lines from the start */
                    return ( textLine.key < textLineIndex && textLine.key >= ( ( maxLines - textLineIndex) <= 0 ? Math.abs( maxLines - textLineIndex ) : 0 ) );
                })
                .map((textLine, index) => {
                    return(<p key={id+"-lineOut-"+index}>{lineInPreText ? lineInPreText + " " + textLine.mask : textLine.mask}</p>);
                })}
            </div>
            <div className="line-in">
                {textLines
                .filter(textLine => { return (textLine.key === textLineIndex) })
                .map((textLine, index) => {
                    if ( style === "terminal" ) {
                        return(<div className="text-line-wrapper"><p key={id+"-lineIn-"+index}>{textLine.mask}</p><hr className={"line-cursor on"}/></div>);
                    }
                    return(<p key={id+"-lineIn-"+index}>{lineInPreText ? lineInPreText + " " + textLine.mask : textLine.mask }{cursor}</p>);
                })}
            </div>
        </div>
    );
}

const useIsInViewport = (ref) => {
    const [intersecting, setIntersecting] = useState(false);
    const observer = useMemo(
        () => 
            new IntersectionObserver(([entry]) => 
                setIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);
    return intersecting;
}

export default TextTelepromter;